<template>
  <a-modal
    v-model="visible"
    :forceRender="true"
    title="Agregar sección"
    class="order-adjust product-edit-modal"
  >
    <div class="modal-content">
      <a-row type="flex">
        Nombre:
        <a-input v-model="section.name" />
        <span class="error-field" v-if="section.name === '' && onCreateClicked">El campo nombre es requerido</span>
      </a-row>
      <a-row type="flex"> </a-row>
    </div>

    <template slot="footer">
      <a-button key="back" @click="$emit('cancel')"> Cerrar </a-button>
      <a-button @click="onOk" class="btn-confirm"> Confirmar </a-button>
    </template>
  </a-modal>
</template>
<script>
export default {
  name: "AddSection",
  data() {
    return {
      name: "",
      onCreateClicked: false
    };
  },
  props: {
    section: {
      name
    },
    visible: {
      default: false,
    },
  },
  methods: {
    onOk() {
      this.onCreateClicked = true;
      if (this.section.name !== "") {
        let data = {
          name: this.section.name,
        };
        this.$emit("confirm", data);
      }
    },
  },
};
</script>
