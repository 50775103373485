<template>
  <a-modal
    v-model="visible"
    class="order-adjust product-edit-modal modal-options"
    v-if="product"
    @ok="onClose"
  >
    <a-form :form="form" @submit.prevent="submitAddData">
      <div class="product-modal-container">
        <div>
          <a-form-item label="Nombre">
            <a-input
              placeholder="Nombre del producto"
              :max-length="255"
              v-decorator="[
                'name',
                {
                  rules: [
                    {
                      required: true,
                      message: 'El campo nombre es requerido.',
                    },
                  ],
                  initialValue: product.name,
                },
              ]"
            />
          </a-form-item>

          <div class="product-grid-row">
            <div>
              <a-form-item label="Precio">
                <a-input-number
                  placeholder="0.00"
                  v-decorator="[
                    'pricing',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'El campo precio es requerido.',
                        },
                      ],
                      initialValue: product.pricing,
                    },
                  ]"
                />
              </a-form-item>
            </div>

            <div>
              <a-form-item label="Precio Local">
                <a-input-number
                  placeholder="0.00"
                  v-decorator="[
                    'qr_pricing',
                    {
                      initialValue: product.qr_pricing,
                    },
                  ]"
                />
              </a-form-item>
            </div>
          </div>
        </div>
      </div>

      <div>
        <a-form-item label="Producto granel">
          <a-switch v-model="bulkProduct"
          />
        </a-form-item>
      </div>

      <div>
        <a-form-item
          :label="`Descripción (${
            product.description ? product.description.length : 0
          }/512)`"
        >
          <a-textarea
            :max-length="512"
            placeholder="Descripción"
            :auto-size="{ minRows: 4, maxRows: 8 }"
            v-decorator="[
              'description',
              {
                initialValue: product.description,
              },
            ]"
          />
        </a-form-item>
      </div>
    </a-form>

    <div>Extras y complementos</div>
    <template v-if="!hasExtras"> No hay extras para este plato </template>
    <a-row type="flex" v-else>
      <a-collapse
        class="product-extras"
        :expand-icon-position="expandIconPosition"
      >
        <a-collapse-panel
          v-for="(option, pindex) in product.options"
          :key="pindex"
          :header="option.list.description"
        >
          <div
            class="product edit-extras"
            v-for="(extra, index) in option.list.extras"
            :key="index"
          >
            <a-row
              type="flex"
              class="extra-container"
              justify="space-around"
              align="middle"
            >
              <a-col class="multi-row" :span="5">
                Nombre
                <a-input
                  v-model="extra.name"
                  :disabled="isExtraEdit(extra.id)"
                />
              </a-col>
              <a-col class="multi-row" :span="5">
                Precio
                <a-input-number
                  :formatter="
                    (value) => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  "
                  :disabled="isExtraEdit(extra.id)"
                  v-model="extra.cost"
                />
              </a-col>
              <a-col class="multi-row" :span="5">
                Precio Local
                <a-input-number
                  :formatter="
                    (value) => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  "
                  :disabled="isExtraEdit(extra.id)"
                  v-model="extra.local_pricing"
                />
              </a-col>
              <a-col class="options" :span="5">
                <a-icon
                  @click="editOrSave(extra)"
                  :type="isExtraEdit(extra.id) ? 'edit' : 'save'"
                  class="icon-button"
                ></a-icon>
                <a-popconfirm
                  title="Seguro que quieres borrar esta opción?"
                  @confirm="deleteExtra(extra.id, index, option.list)"
                  @cancel="() => {}"
                  okText="Borrar"
                  cancelText="Cancelar"
                >
                  <a-icon type="delete" class="icon-button"></a-icon>
                </a-popconfirm>
                <a-switch
                  size="small"
                  v-model="extra.available"
                  @change="toggleAvaiability(extra)"
                />
              </a-col>
            </a-row>
          </div>
          <a-row type="flex">
            <a-button block @click="startCreatingNewOption(pindex)"
              >Agregar opción</a-button
            >
          </a-row>
          <a-divider />
          <a-row
            type="flex"
            class="extras-options"
            justify="space-around"
            align="middle"
          >
            <a-col :span="5">
              <template v-if="option.list.behavior === 'multiple'">
                <span>Limt.&nbsp;&nbsp;</span>
                <a-popover
                  title="El `0` indica que no hay un límite de selección"
                >
                  <a-input-number size="small" v-model="option.list.limit" />
                </a-popover>
              </template>
            </a-col>

            <a-col :span="5">
              <a-checkbox v-model="option.list.required">Reqerido</a-checkbox>
            </a-col>

            <a-col :span="7">
              <a-radio-group v-model="option.list.behavior" size="small">
                <a-radio-button value="multiple">Múltp.</a-radio-button>
                <a-radio-button value="unique">Único</a-radio-button>
              </a-radio-group>
            </a-col>
            <a-col :span="3">
              <a-icon
                :type="currentSavingListIndex === pindex ? 'loading' : 'save'"
                @click="updateList(option.list, pindex)"
              ></a-icon>

              <a-divider type="vertical"></a-divider>
              <a-popconfirm
                title="Seguro que quieres quitar este grupo?"
                @confirm="deleteList(option.list, pindex)"
                @cancel="() => {}"
                okText="Borrar"
                cancelText="Cancelar"
              >
                <a-icon type="delete"></a-icon>
              </a-popconfirm>
            </a-col>
          </a-row>
        </a-collapse-panel>
      </a-collapse>
    </a-row>
    <a-divider />

    <div class="btn-extra-options">
      <a-button class="btn-confirm btn-add-existing" @click="onAttachGroup()">
        Agregar grupo existente
      </a-button>
      <a-button class="btn-confirm" @click="createGroup()">
        Crear nuevo grupo
      </a-button>
    </div>

    <template slot="footer">
      <a-button key="back" @click="onClose"> Cancelar </a-button>

      <a-popconfirm
        :title="`¿Seguro que quieres guardar los cambios?`"
        @confirm="submitAddData"
        @cancel="() => {}"
        okText="OK"
        cancelText="Cancelar"
      >
        <a-button class="btn-confirm"> Guardar </a-button>
      </a-popconfirm>
    </template>
    <CreateGroup
      :visible="isCreateGroupVisible"
      :product="product"
      v-on:postGroup="postGroup"
      v-on:cancel="createGroupVisible = false"
    />
    <a-modal
      v-if="showAttachmentGroupModal"
      v-model="showAttachmentGroupModal"
      :forceRender="true"
      class="order-adjust product-edit-modal extra-modal"
      title="Agrega un grupo existente"
    >
      <div class="attach-extra-container">
        <div
          v-for="list in all_lists"
          :key="list.id"
          :disabled-group="productListsIds.includes(list.id)"
          class="group"
        >
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div>
              <h3>{{ list.description }}</h3>
              <h5 v-if="!productListsIds.includes(list.id)">
                contiene:
                {{
                  list.extras
                    .map((extra) => extra.name)
                    .join(", ")
                    .slice(0, 60) + "..."
                }}
              </h5>
              <h5 v-else>Añadido <a-icon type="check"></a-icon></h5>
            </div>
            <div>
              <a-button
                shape="circle"
                icon="plus"
                @click="addGroup(list, productListsIds.includes(list.id))"
              />
              <a-button
                shape="circle"
                icon="delete"
                @click="deleteGroup(list)"
              />
            </div>
          </div>
        </div>
      </div>
      <template slot="footer"
        >Este grupo se añadirá automáticamente a
        <strong>{{ product.name }}</strong></template
      >
    </a-modal>
    <a-modal
      v-model="showAddOptionModal"
      title="Agrega una opción"
      @ok="validateSaveOption"
      @cancel="showAddOptionModal = false"
      class="modal-options"
    >
      <a-row
        type="flex"
        class="modal-add-option"
        justify="space-around"
        align="middle"
      >
        <a-form :form="createOptionForm" @submit.prevent="submitCreateOption">
          <a-col :span="7">
            <a-form-item label="Nombre">
              <a-input
                placeholder="Nombre"
                v-decorator="[
                  'name',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'El campo nombre es requerido.',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item label="Precio">
              <a-input-number
                :formatter="
                  (value) => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                v-decorator="[
                  'cost',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'El campo precio es requerido.',
                      },
                    ],
                  },
                ]"
              /> </a-form-item
          ></a-col>
          <a-col :span="7">
            <a-form-item label="Precio Local">
              <a-input-number
                :formatter="
                  (value) => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                v-decorator="[
                  'local_pricing',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'El campo precio local es requerido.',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-form>
      </a-row>
    </a-modal>
  </a-modal>
</template>
<script>
import CreateGroup from "@/components/menu/add/CreateGroup";
export default {
  name: "PriceEdit",
  components: {
    CreateGroup,
  },
  data() {
    return {
      all_lists: [],
      createGroupVisible: false,
      createOptionForm: this.$form.createForm(this, {
        name: "newOption",
      }),
      currentExtraEditId: 0,
      currentSavingListIndex: -1,
      expandIconPosition: "left",
      currentListIndexForPushOption: 0,
      form: this.$form.createForm(this, {
        name: "products",
      }),
      product_lists: [],
      showAddOptionModal: false,
      showAttachmentGroupModal: false,
    };
  },
  props: {
    product: {
      available: "",
      description: "",
      name: "",
      pricing: "",
      qr_pricing: "",
      bulk_product: false,
    },
    visible: {
      default: false,
    },
  },
  methods: {
    createGroup() {
      this.createGroupVisible = true;
    },
    extraListDeleted() {
      this.fetchProductExtras();
      this.fetchAllExtras();
      this.showCreateGroupModal = false;
    },
    isAvailable(available) {
      return available === 0 || available === null ? false : true;
    },
    isExtraEdit(extraId) {
      return this.currentExtraEditId !== extraId;
    },
    newGroupCreated(shouldCloseModal) {
      this.fetchProductExtras();
      this.fetchAllExtras();
      if (shouldCloseModal) this.showAttachmentGroupModal = false;
    },
    onAttachGroup() {
      this.fetchProductExtras();
      this.fetchAllExtras();
      this.showAttachmentGroupModal = true;
    },
    onClose() {
      this.$emit("cancel");
    },
    startCreatingNewOption(listIndex) {
      this.currentListIndexForPushOption = listIndex;
      this.showAddOptionModal = true;
    },
    submitProduct() {
      const product = this.form.getFieldsValue();
      this.$emit("submitProduct", {
        product: product,
      });
    },
    submitAddData() {
      this.form.validateFields((err) => {
        if (!err) {
          this.submitProduct();
        }
      });
    },
    async addGroup(list, isAdded) {
      if (isAdded) {
        return;
      }

      const data = {
        product_id: this.product.id,
        establishment_id: this.establishmentId,
        extra_list_id: list.id,
      };

      let respList = await this.$store.dispatch("post", {
        route: "extra-list-group",
        data,
      });

      respList.list = list;

      this.product.options.push(respList)

      this.$notification.success({
        message: "Agregar grupo",
        description: `Se ha agregado el grupo ${list.description}`,
        duration: 5,
      });
      this.showAttachmentGroupModal = false;
    },
    async deleteExtra(extraId, index, list) {
      await this.$store.dispatch("delete", ["extra", extraId].join("/"));
      list.extras.splice(index, 1);
    },
    async deleteGroup(list) {
      if (!list.id) return;

      this.$confirm({
        title: "Eliminar grupo",
        content: `¿Seguro que deseas eliminar este grupo de extras?`,
        onOk: async () => {
          await this.$store
            .dispatch("delete", ["extra-list-group", list.id].join("/"))
            .then(() => {
              const indexElementRemoved = this.product.options.findIndex(p => p.list.id === list.id);
              this.product.options.splice(indexElementRemoved, 1);

              this.$notification.success({
                message: "Eliminar grupo",
                description: `Se ha eliminado el grupo ${list.description}`,
                duration: 5,
              });
            })
            .catch((error) => {
              this.$notification.error({
                message: "Eliminar grupo",
                description: `Hubo un problema al eliminar el grupo ${list.description}`,
                duration: 5,
              });
              console.error(error);
            });
        },
        onCancel: () => {},
      });
      this.newGroupCreated(true);
    },
    async deleteList(list, index) {
      await this.$store.dispatch(
        "delete",
        ["extra-list", list.id, this.product.id].join("/")
      );
      this.product.options.splice(index, 1);
    },
    async editOrSave(extra) {
      if (this.currentExtraEditId === extra.id) {
        try {
          const resp = await this.$store.dispatch("put", {
            route: ["extra", extra.id].join("/"),
            data: extra,
            method: "PUT",
          });
          if (resp.ok) {
            this.$notification.success({
              message: "Editar extra",
              description: "Editado con éxito",
              duration: 5,
            });
          }
        } catch (err) {
          console.log(err);
        }
        this.currentExtraEditId = 0;
      } else {
        this.currentExtraEditId = extra.id;
      }
    },
    async fetchAllExtras() {
      const all_lists = await this.$store.dispatch("get", {
        location: ["extra-lists-establishment", this.establishmentId].join("/"),
      });
      this.all_lists = all_lists;
    },
    async fetchProductExtras() {
      let product_lists = await this.$store.dispatch("get", {
        location: ["extra-lists", this.product.id].join("/"),
      });
      product_lists.forEach((product) => {
        product.list.extras.forEach((extra) => {
          extra.available = extra.available === 1 ? true : false;
        });
      });
      this.product_lists = product_lists;
    },
    async postGroup(data) {
      this.createGroupVisible = false;
      const resp = await this.$store.dispatch("post", {
        route: "extra-list-product",
        data,
      });

      if (resp.ok) {
        this.product.options.push(resp)

        this.fetchProductExtras();
        this.fetchAllExtras();
        data.list = "";
        this.$emit("extraAdded");
      }
    },
    validateSaveOption() {
      this.createOptionForm.validateFields((err) => {
        if (!err) {
          this.saveOption();
        }
      });
    },
    async saveOption() {
      const data = {
        ...this.createOptionForm.getFieldsValue(),
        extra_list_id:
          this.product.options[this.currentListIndexForPushOption].list.id,
      };

      const option = await this.$store.dispatch("post", {
        route: "extra",
        data,
      });
      option.available = true;
      this.product.options[this.currentListIndexForPushOption].list.extras.push(
        option
      );
      this.createOptionForm.resetFields();
      this.showAddOptionModal = false;
    },
    async toggleAvaiability(extra) {
      try {
        await this.$store.dispatch("put", {
          route: ["extra/availability", extra.id].join("/"),
          data: extra,
          method: "PUT",
        });
      } catch (err) {
        console.log(err);
      }
    },
    async updateList(list, index) {
      this.currentSavingListIndex = index;
      await this.$store.dispatch("put", {
        route: ["extra-list", list.id].join("/"),
        data: list,
        method: "PUT",
      });
      setTimeout(() => (this.currentSavingListIndex = -1), 500);
    },
  },
  created() {
    if (this.product) {
      this.fetchProductExtras();
      this.fetchAllExtras();
    }
  },
  computed: {
    available: {
      get() {
        return !!this.product.available;
      },
      set(newValue) {
        return newValue;
      },
    },
    bulkProduct: {
      get: function () {
        return !!this.product.bulk_product;
      },
      set: function (value) {
        return this.$emit("bulkProductChanged", value);
      }
    },
    establishmentId() {
      return this.$store.getters.establishmentId;
    },
    hasExtras() {
      return this.product.options.length > 0;
    },
    productListsIds() {
      return this.product_lists.map((productList) => {
        return productList.list.id;
      });
    },
    isCreateGroupVisible() {
      return !this.$store.getters.isNewOrder && this.createGroupVisible;
    },
  },
};
</script>
