<template>
  <a-modal
    v-model="visible"
    class="order-adjust product-edit-modal extra-modal"
    title="Agregar extra"
    :maskClosable="false"
    :closable="false"
  >
    <a-steps :current="groupCurrentIndex" progressDot>
      <a-step><template slot="title">Crea el grupo</template></a-step>
      <a-step><template slot="title">Añade opciones</template></a-step>
      <a-step><template slot="title">Terminar</template></a-step>
    </a-steps>
    <a-divider />
    <template v-if="groupCurrentIndex === 0">
      <a-form
        class="add-product add-extra"
      >
        <a-row type="flex" justify="space-between" class="user-data">
          <a-col :span="24">
            <a-form-item label="Nombre del grupo">
              <a-input
                placeholder="Ejemplo: Tipo de tortilla"
                :max-length="255"
                v-model="description" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row type="flex" class="user-data">
          <a-col :span="24">
            <a-form-item label="Tipo de lista">
              <a-select v-model="behavior">
                <a-select-option value="unique"
                  >Selección única</a-select-option
                >
                <a-select-option value="multiple" selected
                  >Selección múltiple</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row type="flex" class="user-data">
          <a-col :span="24" v-if="behavior === 'multiple'">
            <a-form-item label="Cantidad límite de elementos a seleccionar">
              <a-popover :title="messageForLimit0">
                <a-input-number v-model="limit" :min="0" />
              </a-popover>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row type="flex" class="user-data">
          <a-col :span="24">
            <a-form-item>
              <a-checkbox v-model="required"
                >Forzar al usuario a seleccionar un elemento de este
                grupo</a-checkbox
              >
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <a-divider />
      <a-row type="flex" justify="space-between" class="extra-details">
        <a-col :span="6">
          <a-button @click="$emit('cancel')">Cancelar</a-button>
        </a-col>
        <a-col :span="16">
          <a-button
            :disabled="!description"
            block
            type="primary"
            @click="groupCurrentIndex = 1"
            >Continuar</a-button
          >
        </a-col>
      </a-row>
    </template>
    <template v-if="groupCurrentIndex === 1">
      <p>{{ messageLessItemsThanLimitIs }}</p>

      <div
        v-for="(option, index) in options"
        :key="index"
        class="flex-row add-extra-products"
      >
        <a-row type="flex" justify="space-between">
          <a-col :span="12">
            <a-input placeholder="Nombre del elemento" v-model="option.name" />
          </a-col>
          <a-col :span="8">
            <a-input-number
              style="width: 130px"
              :formatter="
                (value) => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              "
              v-model="option.cost"
            />
          </a-col>
          <a-col :span="2">
            <a-icon type="delete" class="icon-button" @click="deleteOption(index)"></a-icon>
          </a-col>
        </a-row>
        <span class="error-field" v-if="option.name === '' && !notEmptyStatus">El campo nombre es requerido</span>
      </div>
      <span class="error-field" v-if="!isNotEmptyOptionsList">Agrega al menos una opción</span>
      <a-button class="extra-btn-details" block @click="addOption">
        <a-icon type="plus"></a-icon>
        Añadir elemento
      </a-button>

      <a-divider></a-divider>

      <a-row type="flex" justify="space-between" class="extra-finish">
        <a-col :span="6" class="btn-back">
          <a-button @click="groupCurrentIndex = 0">Volver</a-button>
        </a-col>
        <a-col :span="16" class="extra-btn-details">
          <a-button block type="primary" @click="postGroup"
            >Terminar y guardar</a-button
          >
        </a-col>
      </a-row>
    </template>
    <template v-if="groupCurrentIndex === 2">
      <div class="flex-row flex-center">
        <a-spin>
          <a-icon
            slot="indicator"
            type="loading"
            style="font-size: 24px"
            spin
          />
        </a-spin>
      </div>
    </template>
    <template slot="footer"
      >Este grupo se añadirá automáticamente a
      <strong>{{ product.name }}</strong></template
    >
  </a-modal>
</template>
<script>
export default {
  name: "AddGroup",
  data() {
    return {
      behavior: "unique",
      description: "",
      groupCurrentIndex: 0,
      limit: 0,
      notEmptyStatus: true,
      options: [{ name: "", cost: 0 }],
      required: false,
    };
  },
  props: {
    product: {
      name: "",
      id: "",
    },
    visible: {
      default: false,
    },
  },
  methods: {
    addOption() {
      this.options.push({ name: "", cost: 0 });
    },
    deleteOption(index) {
      this.options.splice(index, 1);      
    },
    postGroup() {
      if (this.validateOptions()) {
        let data = {
          behavior: this.behavior,
          description: this.description,
          establishment_id: this.$store.getters.establishmentId,
          limit: this.limit,
          options: this.options,
          product_id: this.product.id,
          required: this.required
        };

        this.groupCurrentIndex = 0;
        this.required = true;
        this.limit = 0;
        this.description = "";
        this.required = false;
        this.options = [{ name: "", cost: 0 }];

        this.$emit("postGroup", data);
      }
    },
    validateOptions() {
      this.notEmptyStatus = this.options.every(option => option.name !== '');
      return this.notEmptyStatus && this.isNotEmptyOptionsList;
    },
    removeOption(index) {
      this.options.slice(index, 1);
    },
    onOk() {
      let data = {
        name: this.name,
      };
      this.name = "";
      this.$emit("confirm", data);
    },
  },
  computed: {
    isNotEmptyOptionsList() {
      return this.options.length > 0;
    },
    messageForLimit0() {
      return this.limit === 0
        ? 'El valor "0" indica se pueden seleccionar todos los elementos'
        : "El usuario podrá seleccionar hasta " + this.limit + " elementos";
    },
    messageLessItemsThanLimitIs() {
      return this.limit > this.options.length && this.behavior === "multiple"
        ? `Declaraste un límite de selección de ${this.limit} opcione(s), y has creado solo ${this.options.length} opcione(s)`
        : "";
    },
  },
};
</script>
