<template>
  <a-modal
    v-model="visible"
    :forceRender="true"
    title="Editar disponibilidad"
    class="order-adjust product-edit-modal"
  >
    <div class="modal-content">
      <a-row type="flex">
        Selecciona la disponibilidad del producto:
        <br />
        → {{ productName }}
        <br />
        Disponible hasta:
      </a-row>
      <a-row type="flex">
        <a-radio-group v-model="availabilityType">
          <a-radio-button :value="0">Indefinido</a-radio-button>
          <a-radio-button :value="1">Día siguiente</a-radio-button>
        </a-radio-group>
      </a-row>
      <a-divider />
      <a-row type="flex">
        <span
          >Nota: El producto se modificará como
          <strong
            >producto disponible
            {{
              availabilityType == 0
                ? "de manera indefinida"
                : "hasta el día de hoy a las 23:59 PM"
            }}</strong
          ></span
        >
      </a-row>
    </div>

    <template slot="footer">
      <a-button key="back" @click="$emit('cancel')"> Cerrar </a-button>
      <a-button @click="onOk" class="btn-confirm">
        Confirmar
      </a-button>
    </template>
  </a-modal>
</template>
<script>
import moment from "moment";
moment.locale("es");

export default {
  name: "PriceEdit",
  data() {
    return {
      availabilityType: 0,
    };
  },
  props: {
    product: {
      name: {
        type: Object,
        default: null,
      },
    },
    visible: {
      default: false,
    },
  },
  methods: {
    onOk() {
      let data = {
        id: this.product.id,
        availability_type: this.availabilityType,
      };
      this.$emit("confirm", data);
    },
  },
  computed: {
    productName() {
      if (this.product) {
        return this.product.name;
      }
      return "";
    },
  },
};
</script>
