var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{staticClass:"order-adjust product-edit-modal",attrs:{"title":"Agregar producto"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('a-form',{staticClass:"add-product",attrs:{"form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.submitAddData.apply(null, arguments)}}},[_c('a-row',{staticClass:"user-data",attrs:{"type":"flex","justify":"space-between"}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"Nombre"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'name',
              {
                rules: [
                  {
                    required: true,
                    message: 'El campo nombre externo es requerido.',
                  } ],
              } ]),expression:"[\n              'name',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'El campo nombre externo es requerido.',\n                  },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"Nombre del producto","max-length":255}})],1)],1)],1),_c('a-row',{staticClass:"user-data",attrs:{"type":"flex"}},[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"Precio"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'pricing',
              {
                rules: [
                  {
                    required: true,
                    message: 'El campo pricing es requerido.',
                  } ],
              } ]),expression:"[\n              'pricing',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'El campo pricing es requerido.',\n                  },\n                ],\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"Precio Local"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'qr_pricing',
              {
                rules: [
                  {
                    required: false,
                  } ],
              } ]),expression:"[\n              'qr_pricing',\n              {\n                rules: [\n                  {\n                    required: false,\n                  },\n                ],\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"Disponible"}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'available',
              {
                rules: [
                  {
                    required: false,
                  } ],
              } ]),expression:"[\n              'available',\n              {\n                rules: [\n                  {\n                    required: false,\n                  },\n                ],\n              },\n            ]"}],attrs:{"size":"small"}})],1)],1)],1),_c('a-row',{staticClass:"user-data",attrs:{"type":"flex"}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'description',
              {
                rules: [
                  {
                    required: false,
                  } ],
              } ]),expression:"[\n              'description',\n              {\n                rules: [\n                  {\n                    required: false,\n                  },\n                ],\n              },\n            ]"}],attrs:{"max-length":512,"placeholder":"Descripción","auto-size":{ minRows: 4, maxRows: 8 }}})],1)],1)],1)],1),_c('template',{slot:"footer"},[_c('a-button',{key:"back",on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" Cerrar ")]),_c('a-button',{key:"submit",staticClass:"btn-confirm",on:{"click":_vm.submitAddData}},[_vm._v(" Confirmar ")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }