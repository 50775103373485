<template>
  <a-modal
    v-model="visible"
    :class="className"
    title="Menú"
    :maskClosable="false"
  >
    <div class="modal-content">
      <a-row type="flex">
        <a-col :span="2">
          <a-icon v-if="iconType === 0" type="close-circle" />
          <a-icon v-else type="info-circle" />
        </a-col>
        <a-col :span="22" class="modal-error-message">
          {{ message }}
        </a-col>
      </a-row>
    </div>
    <template slot="footer">
      <a-button key="back" @click="$emit('cancel')" class="modal-error-btn-close">
        Cerrar
      </a-button>
    </template>
  </a-modal>
</template>
<script>
export default {
  name: "Error",
  props: {
    visible: {
      default: false,
    },
    message: null,
    iconType: {
      default: 0
    },
  },
  computed: {
    className() {
      return this.iconType === 0 ? "modal-logout custom-modal-error" : "modal-logout custom-modal-alert"
    }
  }
};
</script>