<template>
  <a-modal
    v-model="visible"
    class="order-adjust product-edit-modal"
    title="Agregar producto"
  >
    <a-form :form="form" @submit.prevent="submitAddData" class="add-product">
      <a-row type="flex" justify="space-between" class="user-data">
        <a-col :span="24">
          <a-form-item label="Nombre">
            <a-input
              placeholder="Nombre del producto"
              :max-length="255"
              v-decorator="[
                'name',
                {
                  rules: [
                    {
                      required: true,
                      message: 'El campo nombre externo es requerido.',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row type="flex" class="user-data">
        <a-col :span="8">
          <a-form-item label="Precio">
            <a-input-number
              v-decorator="[
                'pricing',
                {
                  rules: [
                    {
                      required: true,
                      message: 'El campo pricing es requerido.',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="Precio Local">
            <a-input-number
              v-decorator="[
                'qr_pricing',
                {
                  rules: [
                    {
                      required: false,
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="Disponible">
            <a-switch
              size="small"
              v-decorator="[
                'available',
                {
                  rules: [
                    {
                      required: false,
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row type="flex" class="user-data">
        <a-col :span="24">
          <a-form-item>
            <a-textarea
              v-decorator="[
                'description',
                {
                  rules: [
                    {
                      required: false,
                    },
                  ],
                },
              ]"
              :max-length="512"
              placeholder="Descripción"
              :auto-size="{ minRows: 4, maxRows: 8 }"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <template slot="footer">
      <a-button key="back" @click="$emit('cancel')"> Cerrar </a-button>
      <a-button key="submit" @click="submitAddData" class="btn-confirm">
        Confirmar
      </a-button>
    </template>
  </a-modal>
</template>
<script>
export default {
  name: "AddSection",
  data() {
    return {
      form: this.$form.createForm(this, {
        name: "coordinated",
      }),
    };
  },
  props: {
    visible: {
      default: false,
    },
  },
  methods: {
    onOk() {
      const data = {
        ...this.form.getFieldsValue(),
      };
      this.form.resetFields();
      this.$emit("confirm", data);
    },
    submitAddData() {
      this.form.validateFields((err) => {
        if (!err) {
          this.onOk();
        }
      });
    },
  },
};
</script>
